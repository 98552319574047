import React, { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLoader from "../../components/Adways/ContentLoaders/AuthLoader";
import { useEmailAuthentication } from "../../hooks/Auths/email";
import { usePhoneAuthentication } from "../../hooks/Auths/phone";
import { useAuthenticateCustomers } from "../../hooks/Auths/emailPass";
import { useSelector, useDispatch } from "react-redux";
import { setCustomerInfo } from "../../redux/slices/customerSlice";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NoticePopups from "../../components/Adways/NoticePopUps";
import { useLocation, useNavigate, Link } from "react-router-dom";

export interface PageLoginProps {}

// Traditional login validation schema
const passwordLoginSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
});

// Email OTP validation schema
const emailOtpRequestSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
});

// Phone OTP validation schema
const phoneOtpRequestSchema = Yup.object({
  phoneNumber: Yup.string()
    .matches(/^\+?[1-9]\d{1,14}$/, "Please enter a valid phone number")
    .required("Phone number is required"),
});

// OTP verification schema
const otpVerifySchema = Yup.object({
  otp: Yup.string()
    .matches(/^\d{6}$/, "OTP must be 6 digits")
    .required("OTP is required"),
});

const PageLogin: FC<PageLoginProps> = () => {
  // Login method state
  const [activeTab, setActiveTab] = useState("phone"); // Default to email OTP
  const [otpSent, setOtpSent] = useState(false);
  const [authenticationType, setAuthenticationType] = useState("email"); // "email", "phone", or "password"
  
  // Common states
  const [isOpen, setIsOpen] = useState(false);
  const [popupType, setPopupType] = useState("success");
  const [popupMessage, setPopupMessage] = useState("");
  const router = useNavigate();
  const dispatch = useDispatch();
  
  // Password authentication
  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });
  const { data: passwordUserData, isLoading: passwordLoading, error: passwordError } = useAuthenticateCustomers(
    loginDetails.email,
    loginDetails.password
  );
  
  // Phone authentication hook
  const { 
    isLoading: phoneLoading, 
    error: phoneError, 
    userData: phoneUserData,
    recaptchaResolved,
    initializeRecaptcha,
    forceRecaptchaReload, // Added this function to the destructuring
    sendOtpToPhone,
    verifyOtp,
    cleanup: cleanupPhoneAuth
  } = usePhoneAuthentication();
  
  // Email authentication hook
  const { 
    isLoading: emailLoading, 
    error: emailError, 
    userData: emailUserData,
    sendOtpToEmail,
    verifyManualOtp
  } = useEmailAuthentication();
  
  const isLoading = passwordLoading || phoneLoading || emailLoading;

  const openPopup = (type, message) => {
    setPopupType(type);
    setPopupMessage(message);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Initialize reCAPTCHA when switching to phone tab - only one useEffect this time
  useEffect(() => {
    // Only initialize when on phone tab and not yet sent OTP
    if (activeTab === 'phone' && !otpSent) {
      console.log('Initializing reCAPTCHA...');
      // Add a small delay to make sure the container is rendered
      setTimeout(() => {
        initializeRecaptcha('recaptcha-container');
      }, 100);
    }
    
    // Cleanup when unmounting or switching tabs
    return () => {
      if (activeTab !== 'phone') {
        cleanupPhoneAuth();
      }
    };
  }, [activeTab, otpSent]);

  // Handle password authentication result
  useEffect(() => {
    if (passwordUserData) {
      if (passwordUserData.status === false) {
        openPopup("error", passwordUserData.message || "Authentication failed");
      } else if (passwordUserData.status === true) {
        dispatch(setCustomerInfo(passwordUserData.message));
        openPopup("success", "Login successful! Redirecting to your account...");
        setTimeout(() => router('/customer_account'), 1500);
      }
    }
  }, [passwordUserData, dispatch, router]);
  
  // Handle phone authentication result
  useEffect(() => {
    if (phoneUserData) {
      if (phoneUserData.status === false) {
        openPopup("error", phoneUserData.message || "Phone authentication failed");
      } else if (phoneUserData.status === true) {
        dispatch(setCustomerInfo(phoneUserData.message));
        openPopup("success", "Login successful! Redirecting to your account...");
        setTimeout(() => router('/customer_account'), 1500);
      }
    }
  }, [phoneUserData, dispatch, router]);
  
  // Handle email authentication result
  useEffect(() => {
    if (emailUserData) {
      if (emailUserData.status === false) {
        openPopup("error", emailUserData.message || "Email authentication failed");
      } else if (emailUserData.status === true) {
        dispatch(setCustomerInfo(emailUserData.message));
        openPopup("success", "Login successful! Redirecting to your account...");
        setTimeout(() => router('/customer_account'), 1500);
      }
    }
  }, [emailUserData, dispatch, router]);
  
  // Handle authentication errors
  useEffect(() => {
    if (passwordError) {
      openPopup("error", passwordError.message || "Password authentication failed");
    } else if (phoneError) {
      openPopup("error", phoneError || "Phone authentication failed");
    } else if (emailError) {
      openPopup("error", emailError || "Email authentication failed");
    }
  }, [passwordError, phoneError, emailError]);

  // Password login form
  const passwordFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: passwordLoginSchema,
    onSubmit: (values) => {
      setLoginDetails(values);
    },
  });

  // Email OTP request form
  const emailOtpRequestFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailOtpRequestSchema,
    onSubmit: async (values) => {
      setAuthenticationType("email");
      const success = await sendOtpToEmail(values.email);
      if (success) {
        setOtpSent(true);
        openPopup("success", "OTP sent to your email address");
      }
    },
  });

  // Phone OTP request form
  const phoneOtpRequestFormik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: phoneOtpRequestSchema,
    onSubmit: async (values) => {
      setAuthenticationType("phone");
      const success = await sendOtpToPhone(values.phoneNumber);
      if (success) {
        setOtpSent(true);
        openPopup("success", "OTP sent to your phone");
      }
    },
  });

  // OTP verification form
  const otpVerifyFormik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: otpVerifySchema,
    onSubmit: async (values) => {
      if (authenticationType === "phone") {
        await verifyOtp(values.otp);
      } else if (authenticationType === "email") {
        await verifyManualOtp(values.otp);
      }
    },
  });

  // Handler for tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setOtpSent(false);
    
    // Reset form errors when switching tabs
    if (tab === "email") {
      emailOtpRequestFormik.resetForm();
    } else if (tab === "phone") {
      phoneOtpRequestFormik.resetForm();
    } else if (tab === "password") {
      passwordFormik.resetForm();
    }
  };

  // Add a function to handle pasting into OTP inputs
  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').trim();
    
    // Check if pasted content is valid (6 digits)
    if (/^\d{6}$/.test(pastedData)) {
      otpVerifyFormik.setFieldValue('otp', pastedData);
      
      // Auto-submit after a short delay
      setTimeout(() => {
        otpVerifyFormik.submitForm();
      }, 300);
    }
  };

  // Styles for tabs
  const tabStyle = "py-3 px-4 font-medium text-sm cursor-pointer border-b-2 transition-all duration-200";
  const activeTabStyle = `${tabStyle} border-[#03844e] text-[#03844e]`;
  const inactiveTabStyle = `${tabStyle} border-transparent text-neutral-500 hover:text-neutral-700 hover:border-neutral-300`;
  
  return (
    <div className="nc-PageLogin">
      <Helmet>
        <title>Login | Adansi Travel Club</title>
      </Helmet>
      
      <div className="container mt-10 mb-24 lg:mb-32">
        <div className="mx-auto max-w-xl">
          <h2 className="mb-10 text-center text-3xl font-bold text-neutral-900 dark:text-neutral-100">
            Welcome Back
          </h2>
          
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <AuthLoader />
            </div>
          ) : (
            <div className="space-y-8 border border-[#03844e] shadow-lg p-8 rounded-xl bg-white dark:bg-neutral-900">
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:bg-neutral-900 dark:text-neutral-400">
                  Sign in to access your Adansi Account
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>

              {/* Login Tabs */}
              <div className="flex justify-center border-b border-neutral-200">
                <div 
                  className={activeTab === "email" ? activeTabStyle : inactiveTabStyle}
                  onClick={() => handleTabChange("email")}
                >
                  Email OTP
                </div>
                <div 
                  className={activeTab === "phone" ? activeTabStyle : inactiveTabStyle}
                  onClick={() => handleTabChange("phone")}
                >
                  Phone OTP
                </div>
                <div 
                  className={activeTab === "password" ? activeTabStyle : inactiveTabStyle}
                  onClick={() => handleTabChange("password")}
                >
                  Password
                </div>
              </div>

              {/* Email OTP Login Form */}
              {activeTab === "email" && !otpSent && (
                <form className="grid grid-cols-1 gap-6" onSubmit={emailOtpRequestFormik.handleSubmit}>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200 font-medium">Email Address</span>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="mt-1.5"
                      onChange={emailOtpRequestFormik.handleChange}
                      onBlur={emailOtpRequestFormik.handleBlur}
                      value={emailOtpRequestFormik.values.email}
                    />
                    {emailOtpRequestFormik.touched.email && emailOtpRequestFormik.errors.email ? (
                      <div className="text-red-500 text-sm mt-1">{emailOtpRequestFormik.errors.email}</div>
                    ) : null}
                  </label>
                  <ButtonPrimary type="submit" className="mt-2">Send OTP</ButtonPrimary>
                </form>
              )}

              {/* Phone OTP Login Form */}
              {activeTab === "phone" && !otpSent && (
                <form className="grid grid-cols-1 gap-6" onSubmit={phoneOtpRequestFormik.handleSubmit}>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200 font-medium">Phone Number</span>
                    <Input
                      type="tel"
                      name="phoneNumber"
                      placeholder="Enter with country code (e.g. +1234567890)"
                      className="mt-1.5"
                      onChange={phoneOtpRequestFormik.handleChange}
                      onBlur={phoneOtpRequestFormik.handleBlur}
                      value={phoneOtpRequestFormik.values.phoneNumber}
                    />
                    {phoneOtpRequestFormik.touched.phoneNumber && phoneOtpRequestFormik.errors.phoneNumber ? (
                      <div className="text-red-500 text-sm mt-1">{phoneOtpRequestFormik.errors.phoneNumber}</div>
                    ) : null}
                  </label>
                  
                  {/* reCAPTCHA container with explicit styling */}
                  <div 
                    id="recaptcha-container" 
                    className="block my-4 mx-auto min-h-[78px] border border-gray-200 rounded"
                    style={{ width: '100%', minHeight: '78px' }}
                  ></div>
                  
                  {/* Add a manual reload button for recaptcha */}
                  {!recaptchaResolved && (
                    <div className="text-center">
                      <button 
                        type="button"
                        className="text-sm text-[#03844e] underline"
                        onClick={() => forceRecaptchaReload()}
                      >
                        Click here if reCAPTCHA doesn't appear
                      </button>
                    </div>
                  )}
                  
                  <ButtonPrimary 
                    type="submit" 
                    className="mt-2"
                    disabled={!recaptchaResolved && activeTab === "phone"}
                  >
                    {recaptchaResolved ? "Send OTP" : "Solve reCAPTCHA first"}
                  </ButtonPrimary>
                </form>
              )}

              {/* OTP Verification Form */}
              {(activeTab === "email" || activeTab === "phone") && otpSent && (
                <form className="grid grid-cols-1 gap-6" onSubmit={otpVerifyFormik.handleSubmit}>
                  <div className="text-center mb-2">
                    <div className="text-neutral-600 dark:text-neutral-300">
                      {authenticationType === "email" ? (
                        <span>OTP sent to <span className="font-medium">{emailOtpRequestFormik.values.email}</span></span>
                      ) : (
                        <span>OTP sent to <span className="font-medium">{phoneOtpRequestFormik.values.phoneNumber}</span></span>
                      )}
                    </div>
                  </div>
                  
                  <div className="block">
                    <span className="text-neutral-800 dark:text-neutral-200 font-medium block mb-3">Enter OTP</span>
                    
                    <div className="flex justify-between gap-2 mb-2" onPaste={handleOtpPaste}>
                      {[0, 1, 2, 3, 4, 5].map((index) => (
                        <input
                          key={index}
                          type="text"
                          inputMode="numeric"
                          maxLength={1}
                          className="w-12 h-14 rounded-md border border-neutral-200 dark:border-neutral-700 text-center text-xl font-bold focus:border-[#03844e] focus:ring-[#03844e] focus:outline-none shadow-sm"
                          onKeyDown={(e) => {
                            // Navigate to previous input on backspace if current is empty
                            if (e.key === 'Backspace' && e.currentTarget.value === '') {
                              const prevInput = e.currentTarget.previousElementSibling as HTMLInputElement;
                              if (prevInput) {
                                prevInput.focus();
                              }
                            }
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value && /^\d$/.test(value)) {
                              // Create a new OTP value by replacing the digit at the current index
                              const newOtp = otpVerifyFormik.values.otp.split('');
                              newOtp[index] = value;
                              
                              // Update formik value for OTP
                              otpVerifyFormik.setFieldValue('otp', newOtp.join(''));
                              
                              // Move focus to next input if available
                              const nextInput = e.currentTarget.nextElementSibling as HTMLInputElement;
                              if (nextInput) {
                                nextInput.focus();
                              } else {
                                // If this is the last input and all 6 digits are filled, submit the form
                                if (newOtp.join('').length === 6) {
                                  setTimeout(() => {
                                    otpVerifyFormik.submitForm();
                                  }, 300);
                                }
                              }
                            } else {
                              // If non-digit, clear the input
                              e.target.value = '';
                            }
                          }}
                          // Auto-populate from formik value
                          value={otpVerifyFormik.values.otp[index] || ''}
                          onFocus={(e) => e.target.select()}
                        />
                      ))}
                    </div>
                    
                    {otpVerifyFormik.touched.otp && otpVerifyFormik.errors.otp ? (
                      <div className="text-red-500 text-sm mt-1">{otpVerifyFormik.errors.otp}</div>
                    ) : null}
                  </div>
                  
                  <div className="flex justify-between items-center">
                    <button 
                      type="button" 
                      className="text-[#03844e] text-sm font-medium"
                      onClick={() => setOtpSent(false)}
                    >
                      {authenticationType === "email" ? "Change Email" : "Change Phone"}
                    </button>
                    
                    <button 
                      type="button" 
                      className="text-[#03844e] text-sm font-medium"
                      onClick={() => {
                        if (authenticationType === "email") {
                          emailOtpRequestFormik.submitForm();
                        } else {
                          phoneOtpRequestFormik.submitForm();
                        }
                        openPopup("info", `Resending OTP to your ${authenticationType === "email" ? "email" : "phone"}`);
                      }}
                    >
                      Resend OTP
                    </button>
                  </div>
                  
                  <ButtonPrimary type="submit" className={otpVerifyFormik.values.otp.length === 6 ? "opacity-100" : "opacity-70"}>
                    {otpVerifyFormik.values.otp.length === 6 ? "Verifying..." : "Verify & Login"}
                  </ButtonPrimary>
                  
                  {otpVerifyFormik.values.otp.length === 6 && (
                    <div className="text-center text-sm text-neutral-600 dark:text-neutral-400">
                      Verification will start automatically once all digits are entered
                    </div>
                  )}
                </form>
              )}

              {/* Password Login Form */}
              {activeTab === "password" && (
                <form className="grid grid-cols-1 gap-6" onSubmit={passwordFormik.handleSubmit}>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200 font-medium">Email Address</span>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="mt-1.5"
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                      value={passwordFormik.values.email}
                    />
                    {passwordFormik.touched.email && passwordFormik.errors.email ? (
                      <div className="text-red-500 text-sm mt-1">{passwordFormik.errors.email}</div>
                    ) : null}
                  </label>
                  
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200 font-medium">
                      Password
                      <Link to="/forgot-password" className="text-sm font-medium text-[#03844e]">
                        Forgot password?
                      </Link>
                    </span>
                    <Input
                      type="password"
                      name="password"
                      className="mt-1.5"
                      placeholder="Enter your password"
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                      value={passwordFormik.values.password}
                    />
                    {passwordFormik.touched.password && passwordFormik.errors.password ? (
                      <div className="text-red-500 text-sm mt-1">{passwordFormik.errors.password}</div>
                    ) : null}
                  </label>
                  
                  <ButtonPrimary type="submit">Login</ButtonPrimary>
                </form>
              )}

              <div className="pt-4 border-t border-neutral-200 dark:border-neutral-700">
                <div className="text-center space-y-4">
                  <p className="text-sm text-neutral-700 dark:text-neutral-300">
                    Don't have an account?
                  </p>
                  <Link 
                    to="https://thebosstravelclub.com" 
                    className="inline-block rounded-lg border-2 border-[#03844e] px-6 py-2 text-sm font-medium text-[#03844e] hover:bg-[#03844e] hover:text-white transition-colors"
                  >
                    Become a club member
                  </Link>
                </div>
              </div>
            </div>
          )}

          <NoticePopups isOpen={isOpen} closeModal={closeModal} type={popupType} message={popupMessage} />
        </div>
      </div>
    </div>
  );
};

export default PageLogin;