import React, { useState, useEffect } from "react";
import TopDestinations from "./topRecentDestination";
import AllDestinations from "./all_destination";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../../images/hero-right-car.png";

// Hero section component
const HeroSection: React.FC = () => {
  const navigate = useNavigate();

  const handleBuyClick = () => {
    // Smooth scroll to destinations section
    const destinationsSection = document.getElementById("destinations-section");
    if (destinationsSection) {
      destinationsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleTopUpClick = () => {
    // Navigate to top-up page or open modal
    // This is a placeholder - implement the actual functionality
    window.open("https://topup.adansi.com", "_blank");
  };

  return (
    <div className="relative w-full overflow-hidden bg-gradient-to-r from-green-50 to-blue-50 dark:from-green-900/20 dark:to-blue-900/20">
      <div className="absolute inset-0 bg-pattern opacity-5"></div>
      
      <div className="container mx-auto px-4 py-16 sm:py-20 md:py-24">
        <div className="flex flex-col md:flex-row items-center justify-between gap-10">
          {/* Left content - text and buttons */}
          <div className="w-full md:w-1/2 space-y-6 text-center md:text-left z-10">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 dark:text-white leading-tight">
              <span className="text-[#03844e]">Connect</span> Globally with 
              <span className="text-[#FF6B35]"> Instant eSIM</span>
            </h1>
            
            <p className="text-lg text-gray-600 dark:text-gray-300 max-w-xl">
              Stay connected in over 120+ countries with our affordable eSIM plans. 
              Instant activation, no physical SIM needed.
            </p>
            
            {/* Key Benefits */}
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 mt-4">
              <div className="flex items-center space-x-2">
                <div className="bg-green-100 dark:bg-green-900/30 p-2 rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-600 dark:text-green-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Instant Activation</span>
              </div>
              
              <div className="flex items-center space-x-2">
                <div className="bg-green-100 dark:bg-green-900/30 p-2 rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-600 dark:text-green-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Affordable Roaming</span>
              </div>
              
              <div className="flex items-center space-x-2">
                <div className="bg-green-100 dark:bg-green-900/30 p-2 rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-600 dark:text-green-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Global Coverage</span>
              </div>
            </div>
            
            {/* CTA Buttons */}
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 pt-4">
              <button 
                onClick={handleBuyClick}
                className="px-8 py-3 bg-[#03844e] text-white rounded-lg shadow-lg hover:bg-[#026d3e] transition duration-300 transform hover:-translate-y-1 font-medium text-lg"
              >
                Buy eSIM
              </button>
              
              <button 
                onClick={handleTopUpClick}
                className="px-8 py-3 bg-white text-[#FF6B35] border-2 border-[#FF6B35] rounded-lg shadow-md hover:bg-[#FF6B35] hover:text-white transition duration-300 transform hover:-translate-y-1 font-medium text-lg"
              >
                Top Up
              </button>
            </div>
          </div>
          
          {/* Right content - eSIM illustration */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <div className="relative w-full max-w-md">
              {/* Main eSIM Card Illustration */}
              <div className="bg-gradient-to-br from-[#03844e] to-[#0284c7] rounded-2xl shadow-2xl p-8 aspect-[5/3] transform rotate-6 relative z-10">
                <div className="absolute top-4 left-4 w-12 h-12 bg-[#FF6B35] rounded-lg rotate-12 shadow-lg"></div>
                
                <div className="absolute top-0 right-0 w-32 h-32">
                  <div className="absolute w-full h-full border-4 border-white/20 rounded-full animate-ping"></div>
                  <div className="absolute w-3/4 h-3/4 top-1/8 left-1/8 border-4 border-white/40 rounded-full animate-ping" style={{ animationDelay: '0.5s' }}></div>
                </div>
                
                <div className="absolute bottom-8 left-8 text-white text-3xl font-bold">eSIM</div>
                <div className="absolute bottom-4 left-8 text-white/70 text-sm">Stay Connected Worldwide</div>
              </div>
              
              {/* Decorative elements */}
              <div className="absolute w-full h-full top-6 left-6 bg-gradient-to-br from-[#FF6B35]/80 to-[#FF9935]/80 rounded-2xl -z-10"></div>
              
              {/* Small decorative cards */}
              <div className="absolute -bottom-10 -left-10 w-32 bg-white p-3 rounded-lg shadow-lg z-20 transform -rotate-12">
                <div className="text-xs text-gray-500 font-medium">GLOBAL</div>
                <div className="text-sm font-bold text-gray-800">120+ Countries</div>
              </div>
              
              <div className="absolute -top-6 -right-6 w-36 bg-white p-3 rounded-lg shadow-lg z-20 transform rotate-12">
                <div className="text-xs text-gray-500 font-medium">ACTIVATION</div>
                <div className="text-sm font-bold text-gray-800">Just Scan & Go</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main eSIM component
const E_SIM: React.FC = () => {
  // Add a class to the body for the background pattern
  useEffect(() => {
    document.body.classList.add('bg-pattern-subtle');
    return () => {
      document.body.classList.remove('bg-pattern-subtle');
    };
  }, []);

  return (
    <div className="relative">
      {/* Hero Section */}
      <HeroSection />
      
      {/* Benefits Section */}
      <div className="bg-white dark:bg-neutral-900 py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">Why Choose Our eSIM?</h2>
            <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
              Experience seamless connectivity with our digital eSIM solution designed for global travelers.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Benefit 1 */}
            <div className="bg-gray-50 dark:bg-neutral-800 p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="w-12 h-12 bg-green-100 dark:bg-green-900/30 rounded-full flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-600 dark:text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Instant Activation</h3>
              <p className="text-gray-600 dark:text-gray-400">
                No need to hunt for local SIM cards. Activate your eSIM instantly with a simple QR code scan.
              </p>
            </div>
            
            {/* Benefit 2 */}
            <div className="bg-gray-50 dark:bg-neutral-800 p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="w-12 h-12 bg-green-100 dark:bg-green-900/30 rounded-full flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-600 dark:text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Cost-Effective</h3>
              <p className="text-gray-600 dark:text-gray-400">
                Save on expensive roaming charges with our affordable data plans tailored for international travelers.
              </p>
            </div>
            
            {/* Benefit 3 */}
            <div className="bg-gray-50 dark:bg-neutral-800 p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="w-12 h-12 bg-green-100 dark:bg-green-900/30 rounded-full flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-600 dark:text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Global Coverage</h3>
              <p className="text-gray-600 dark:text-gray-400">
                Stay connected in over 120+ countries worldwide with reliable high-speed data.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Destinations Section */}
      <div id="destinations-section" className="py-16 bg-gray-50 dark:bg-neutral-950/50">
        <div className="container mx-auto px-4">
          <TopDestinations />
          <div className="mt-16">
            <AllDestinations />
          </div>
        </div>
      </div>
      
      {/* How it Works Section */}
      <div className="bg-white dark:bg-neutral-900 py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">How It Works</h2>
            <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
              Get connected in three simple steps
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Step 1 */}
            <div className="relative">
              <div className="absolute -left-4 -top-4 w-12 h-12 bg-[#03844e] rounded-full flex items-center justify-center text-white font-bold text-xl">1</div>
              <div className="bg-gray-50 dark:bg-neutral-800 p-6 pt-10 rounded-xl shadow-sm">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Purchase Your eSIM</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Choose your destination and select the data plan that suits your needs.
                </p>
              </div>
            </div>
            
            {/* Step 2 */}
            <div className="relative">
              <div className="absolute -left-4 -top-4 w-12 h-12 bg-[#03844e] rounded-full flex items-center justify-center text-white font-bold text-xl">2</div>
              <div className="bg-gray-50 dark:bg-neutral-800 p-6 pt-10 rounded-xl shadow-sm">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Receive QR Code</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Get your QR code instantly via email after your purchase is complete.
                </p>
              </div>
            </div>
            
            {/* Step 3 */}
            <div className="relative">
              <div className="absolute -left-4 -top-4 w-12 h-12 bg-[#03844e] rounded-full flex items-center justify-center text-white font-bold text-xl">3</div>
              <div className="bg-gray-50 dark:bg-neutral-800 p-6 pt-10 rounded-xl shadow-sm">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Scan & Connect</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Scan the QR code with your phone and follow the simple setup instructions to get connected.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default E_SIM;