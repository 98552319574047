import BgGlassmorphism from "../../../components/BgGlassmorphism/BgGlassmorphism";
import VisaButton from "../../../shared/Button/AdwayBtn";
import React, { useState, Fragment } from "react";
import Accordion from "./resources/accordion";
import Hero from "./Hero";
import vibeImage from  "../../../images/slider-img/namibia.jpg";
import visa0img from  "../../../images/slider-img/visa-img.jpg";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ApplicationForm from "./form/page";
import { Player } from '@lottiefiles/react-lottie-player';
import visaAnimation from "../../../LottieFiles/visa.json";

const Page = () => {
  const [applyForm, setApplyForm] = useState(false);
  const [animationLoading, setAnimationLoading] = useState(true);

  const applyBtn = () => {
    setApplyForm(!applyForm);
  };
  
  const visaPrice = [
    {
      country: "South Africa",
      adansiFees: "$ 150",
      visaFee: "₵ 620",
    },
    {
      country: "Malaysia",
      adansiFees: "$150",
      visaFee: "$30",
    },
    {
      country: "Turkey", 
      adansiFees: "$150",
      visaFee: "₵3,300",
    },
    {
      country: "Europe-Schengen",
      adansiFees: "$200",
      visaFee: "€90",
    },
    {
      country: "UK",
      adansiFees: "$300",
      visaFee: "$155",
    },
    {
      country: "USA",
      adansiFees: "$400",
      visaFee: "$185",
    },
    {
      country: "Canada",
      adansiFees: "$400",
      visaFee: "CAD185",
    },
    {
      country: "UAE - Dubai",
      adansiFees: "$130",
      visaFee: "Included",
    },
    {
      country: "Morocco",
      adansiFees: "$150",
      visaFee: "$50",
    },
    {
      country: "Thailand",
      adansiFees: "$100",
      visaFee: "$50",
    },
    {
      country: "India",
      adansiFees: "$100",
      visaFee: "$75",
    },
    {
      country: "Indonesia",
      adansiFees: "$250",
      visaFee: "Included",
    },
    {
      country: "Egypt",
      adansiFees: "$300",
      visaFee: "Included",
    },
    {
      country: "Others",
      adansiFees: "$200",
      visaFee: "To Be Advised",
    },
  ];

  const checkIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-5 h-5 text-green-600"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );

  const crossIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-5 h-5 text-red-600"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );

  const additionRequirements = [
    {
      question: "EMPLOYEE",
      answer:
        "Introductory letter from the Workplace, Pay slips, Salary Bank Statement and any other personal bank statement if available",
    },
    {
      question: "SELF EMPLOYED",
      answer:
        "I Business Bank Statemen, Personal Bank statement,  Business registration documents from registrar-general, Proof of active business, Tax clearance, is not mandatory for all countries,  Bill of laden if available (if anything has ever been imported in recent period)",
    },
    {
      question: "STUDENTS",
      answer:
        "Official Introduction letter from the university or school including proof of paid school fees and school reports/Transcripts, If self-sponsoring, must be able to provide either A or B requirements, If being sponsored, the sponsor must be able to provide sponsorship letter along with either A or B",
    },
    {
      question: "RETIREES",
      answer:
        "Evidence of last employment before retirement, Bank Account Statement for recent 3 months, SSNIT Statement of Account if available, If being sponsored, the sponsor must be able to provide a sponsorship letter along with either A or B",
    },
    {
      question: "HOUSE WIVES",
      answer:
        "Sponsorship letter from the spouse along with either A or B documents, Marriage certificates and birth certificates of children are mandatory",
    },
  ];

  const faq = [
    {
      question: "Do I require travel experience?",
      answer:
        "A good travel experience is required for US and Canada visa application in all situations. For UK and Europe, travel experience is not compulsory but it greatly enhances your chance of the visa. Generally, travel experience increases your credibility and affect your chance of acquiring the visa. Certain countries like UAE – Dubai, South Africa and the like do not require travel experience at all",
    },
    {
      question: "Which Country can I start with?",
      answer:
        "Most people start off with a trip to Dubai, South Africa, Singapore or some African countries to gain some travel experience before applying to UK, Europe, Canada or USA.",
    },
    {
      question: "Will I get refund if my visa is refused?",
      answer:
        "No please. Our visa consultation fee is non-refundable and the visa fees paid to embassies are also non-refundable. Kindly ensure you meet the criteria before you apply.",
    },
    {
      question: "What is the payment plan for the consultation?",
      answer:
        "We require a minimum of 50% before we start the process and full payment before visa submission is done. Most clients find it useful to pay the consultation in full.",
    },
    {
      question: "What is the mode of payment for the visa fee",
      answer:
        "We accept Ghana cedis at the prevailing dollar rate at the time of payment and payment can be done via Ecobank or Mobile money or online. Your visa consultant will send you the payment details.After paying my consultation fees, how long is it valid?All consultation fees paid us is valid for 1 year. This means, we will be assisting you to put your documents together even if it takes a whole year to do so. Once the application is done, the consultation fee is exhausted and new fees will be charged for another application.",
    },
    {
      question: "Do you have a discount for families?",
      answer:
        "We love to promote family travels and therefore we have a generous offer for families.The spouse of the main applicant pays 75% whiles the children if any pays only 50% of the main applicant. This is not applicable for UAE – Dubai visa application. For UAE visas, we give 10% discount for all family visa application.",
    },
    {
      question: "How much account balance should I have?",
      answer:
        "Introductory letter from the Workplace, Pay slips, Salary Bank Statement and any other personal bank statement if available",
    },
    {
      question: "How much account balance should I have?",
      answer:
        "Please note that there is no standard balance required by Embassies for the visa to be granted. You should have enough in your account to demonstrate your ability to offset the cost of your travel and also for basic living expenses after your trip. Most travelers have found a balance not below GH 30,000 to be fair balance to present. However, we have had some people getting the visa with even less amount as other factors are considered in the visa decision making. Our visa consultant will guide you in this regard.",
    },
    {
      question:
        "If I don't have certain personal documents required like bank statement, can Adansi help?",
      answer:
        "No. we are unable to provide personal bank statement or any documents deemed personal to the applicant. However, if you are working, we will be able to advise you on how to keep your bank accounts or how to register or run your business in order to qualify for the application in the future.",
    },
    {
      question: "If visa is granted, what will be the duration?",
      answer:
        "The visa duration for each destination is determined by the respective consular handling the application and we have no control over that. However, the following duration has been very common with our applications over the years: DUBAI – 1 month or 3 months depending on what you pay for. It could be single or multiple entry depending on what you pay. TURKEY – 1-year single entry visa. SOUTH AFRICA – 3 months single entry. EUROPE: First time or even second time travelers usually get exactly as per the duration of the visa request. Some embassies may also give up to 3 months at their own discretion. Frequent travelers can get multiple entry up to 3years and may not need to pay extra for this. UK: 6 months multiple is the minimum, but frequent UK travelers can request for more by paying extra visa fees. USA: 5 years multiple entry visa is usually the case for B1/B2 visas. CANADA: Multiple entry and up to expiry of your passport for adults. Children could be given less duration.",
    },
    {
      question:
        "If I pay the consultation fees of Adansi, does this guarantee my visa",
      answer:
        "We are not able to guarantee the visas for any of our trips as visa decisions are solely at the discretion of the respective Embassy. We however, do all we can to provide professional guidance to all applicants for the visas to be granted. Since 2017, we have assisted over 1000 Ghanaians to attain Europe, UK, US and Canada visas for their travels. We bring this experience in helping you with your visa application once you qualify as per the requirements stated on this page.",
    },
    {
      question:
        "What is the success rate of visa application by Adansi Travels?",
      answer:
        "As per our existing data up to August 2022, below success rate has been achieved by our visa team. UAE, Malaysia and South Africa – 100% (no refusal so far). Europe Schengen and Turkey – 90%. UK Tourist visa – 70%. UK Student visa – 95%. USA Student visa – 90%. USA Tourist visa – 75%. Canada Student visa – 90%. Canada Tourist visa – 80%",
    },
  ];

  const Amenities_demos = [
    { name: "Initial Assessment of personal profile", icon: checkIcon },
    { name: "Flight Itinerary booking", icon: checkIcon },
    { name: "Hotel Reservation (accommodation cost not included)", icon: checkIcon },
    {
      name: "Travel Insurance booking where applicable (Insurance fees not included)",
      icon: checkIcon,
    },
    { name: "Documents Vetting and Validation", icon: checkIcon },
    { name: "Visa Application Form filling", icon: checkIcon },
    {
      name: "Online Submission of documents where applicable like UK, Canada, USA",
      icon: checkIcon,
    },
    {
      name: "Submission or Interview appointment booking where applicable",
      icon: checkIcon,
    },
    { name: "Interview preparation drill, where applicable", icon: checkIcon },
    { name: "Tour Itinerary if visa is being applied", icon: checkIcon },
    {
      name: "Support in preparing any required letters like introductory or sponsorship letter",
      icon: checkIcon,
    },
  ];

  const Amenities_demos2 = [
    { name: "Visa fees", icon: crossIcon },
    { name: "Application center fees where applicable", icon: crossIcon },
    { name: "Travel Insurance fees", icon: crossIcon },
  ];

  const Amenities_demos3 = [
    {
      name: "Valid Passport: The expiring date in your passport must be 6 months or more from the date of travel",
      icon: checkIcon,
    },
    {
      name: "Personal Birth Certificate, mandatory for only specific Europe countries like Germany",
      icon: checkIcon,
    },
    { name: "Marriage certificate, applicable if married", icon: checkIcon },
    {
      name: "Children birth certificates if you have any, doesn't matter whether they are travelling or not",
      icon: checkIcon,
    },
    {
      name: "Yellow Fever Certificate – Only South Africa and Malaysia visas need this",
      icon: checkIcon,
    },
  ];

  const renderApplyModalBox = () => {
    return (
      <Transition appear show={applyForm} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={applyBtn}
        >
          <div className="min-h-screen px-1 sm:px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>
  
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-xl font-medium leading-6 text-gray-900 dark:text-white"
                      id="headlessui-dialog-title-70"
                    >
                      Visa Request Form
                    </h3>
                    <button
                      onClick={applyBtn}
                      className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 dark:hover:bg-neutral-800 transition-colors"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-600 dark:text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
  
                  <div className="flex overflow-y-auto flex-col items-center justify-center h-full space-y-4">
                    <div id="message" className="text-center px-8 sm:px-20 py-6 pb-4 text-gray-600 dark:text-gray-300">
                      We kindly request that you complete this form accurately to help us process your application further.
                    </div>
                    <div className="h-[500px] w-full px-2 sm:px-20">
                      <ApplicationForm applyBtn={applyBtn} />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className="nc-PageAbout overflow-hidden relative bg-gradient-to-b from-gray-50 via-white to-gray-100 dark:from-neutral-950 dark:via-neutral-900 dark:to-neutral-950"
      style={{
        backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'100\' height=\'100\' viewBox=\'0 0 100 100\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
        backgroundAttachment: 'fixed'
      }}>
      <Hero img={vibeImage} />
      <BgGlassmorphism />
      
      {/* Additional decorative elements */}
      <div className="absolute top-0 right-0 w-1/3 h-full pointer-events-none opacity-10 dark:opacity-5" 
        style={{
          backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'80\' height=\'80\' viewBox=\'0 0 80 80\'%3E%3Cg fill=\'%239C92AC\' fill-opacity=\'0.1\'%3E%3Cpath fill-rule=\'evenodd\' d=\'M0 0h40v40H0V0zm40 40h40v40H40V40zm0-40h2l-2 2V0zm0 4l4-4h2l-6 6V4zm0 4l8-8h2L40 10V8zm0 4L52 0h2L40 14v-2zm0 4L56 0h2L40 18v-2zm0 4L60 0h2L40 22v-2zm0 4L64 0h2L40 26v-2zm0 4L68 0h2L40 30v-2zm0 4L72 0h2L40 34v-2zm0 4L76 0h2L40 38v-2zm0 4L80 0v2L42 40h-2zm4 0L80 4v2L46 40h-2zm4 0L80 8v2L50 40h-2zm4 0l28-28v2L54 40h-2zm4 0l24-24v2L58 40h-2zm4 0l20-20v2L62 40h-2zm4 0l16-16v2L66 40h-2zm4 0l12-12v2L70 40h-2zm4 0l8-8v2l-6 6h-2zm4 0l4-4v2l-2 2h-2z\' /%3E%3C/g%3E%3C/svg%3E")',
          transform: "rotateY(180deg)"
        }}>
      </div>
      
      <div className="absolute top-[30%] left-0 w-1/4 h-[70%] pointer-events-none opacity-10 dark:opacity-5" 
        style={{
          backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'100\' height=\'100\' viewBox=\'0 0 100 100\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5z\' fill=\'%234A5568\' fill-opacity=\'0.15\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")'
        }}>
      </div>
      
      <div className="container py-16 lg:py-20 space-y-16 lg:space-y-24">
        {/* Introduction Section */}
        <section className="bg-white/90 backdrop-blur-sm dark:bg-neutral-900/90 rounded-2xl shadow-sm p-6 md:p-8 lg:p-10 border border-gray-100 dark:border-neutral-800">
          <div className="flex flex-col">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-6 inline-flex items-center">
              <span className="mr-4">VISA REQUEST PORTAL</span>
              <span className="h-1 w-10 bg-green-600 rounded-full"></span>
            </h1>
            <p className="text-gray-600 dark:text-gray-300 text-lg leading-relaxed max-w-4xl">
              We are delighted you have chosen us to assist you with your visa
              processing. We bring our many years of experience, helping
              thousands of Ghanaians travel around the world to help you go
              through the visa process smoothly and successfully. We entreat you to take your time and go through the information
              below.
            </p>
            <div className="mt-8">
              <VisaButton onClick={applyBtn} className="text-base px-8 py-3.5 font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-0.5">
                Start Visa Application
              </VisaButton>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section className="bg-gradient-to-br from-white to-gray-50 dark:from-neutral-900 dark:to-neutral-800 rounded-2xl shadow-sm overflow-hidden border border-gray-100 dark:border-neutral-800">
          <div className="p-6 md:p-8 lg:p-10">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white mb-2">
              Visa Pricing & Fees
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-8">
              View our transparent pricing structure for different destinations
            </p>

            <div className="grid grid-cols-1 md:grid-cols-5 gap-8 items-start">
              <div className="md:col-span-3 overflow-hidden rounded-xl border border-gray-200 dark:border-neutral-800">
                <table className="w-full text-sm">
                  <thead className="bg-gray-100 dark:bg-neutral-800">
                    <tr>
                      <th scope="col" className="px-5 py-4 text-left text-gray-700 dark:text-gray-200 font-semibold uppercase tracking-wider">
                        Country
                      </th>
                      <th scope="col" className="px-5 py-4 text-left text-gray-700 dark:text-gray-200 font-semibold uppercase tracking-wider">
                        Adansi Fees
                      </th>
                      <th scope="col" className="px-5 py-4 text-left text-gray-700 dark:text-gray-200 font-semibold uppercase tracking-wider">
                        Visa Fees
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {visaPrice.map((visa, index) => (
                      <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-white dark:bg-neutral-900' : 'bg-gray-50 dark:bg-neutral-800'} hover:bg-gray-100 dark:hover:bg-neutral-700 transition-colors duration-150`}>
                        <th scope="row" className="px-5 py-3 font-medium text-gray-900 dark:text-gray-200">
                          {visa.country}
                        </th>
                        <td className="px-5 py-3 text-gray-700 dark:text-gray-300">{visa.adansiFees}</td>
                        <td className="px-5 py-3 text-gray-700 dark:text-gray-300">{visa.visaFee}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
              <div className="md:col-span-2">
                <div className="rounded-xl overflow-hidden shadow-md h-full">
                  <img
                    className="w-full h-full object-cover"
                    src={visa0img}
                    alt="Passports with boarding passes"
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 p-5 bg-gray-50 dark:bg-neutral-800 rounded-lg border-l-4 border-amber-500">
              <p className="text-gray-700 dark:text-gray-300 text-sm">
                <span className="font-semibold">Note:</span> Visa fees are to be paid either with your
                visa/master card or directly to the embassy or application
                center at the time of submission. The embassy or application
                center may change the rate at their discretion.
              </p>
              <p className="text-gray-700 dark:text-gray-300 text-sm mt-2">
                Some Schengen State embassies require a booking fee to be paid to
                VFS (Price varies as per the Schengen state involved and will be
                advised per embassy being used).
              </p>
            </div>
          </div>
        </section>

        {/* What Our Fees Cover Section */}
        <section className="bg-gradient-to-br from-blue-50 to-gray-50 dark:from-blue-950/30 dark:to-neutral-900 rounded-2xl shadow-sm p-6 md:p-8 lg:p-10 border border-blue-100/50 dark:border-blue-900/30">
          <div className="flex flex-col space-y-12">
            <div>
              <h2 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white mb-6">
                What Our Service Includes
              </h2>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {Amenities_demos.filter((_, i) => i < 11).map((item, index) => (
                  <div key={index} className="flex items-start space-x-3 p-4 rounded-lg bg-white/80 dark:bg-neutral-800/80 backdrop-blur-sm border border-blue-100 dark:border-blue-800/30 hover:border-blue-200 dark:hover:border-blue-700/40 transition-all duration-200">
                    <div className="flex-shrink-0 mt-0.5">{item.icon}</div>
                    <span className="text-gray-700 dark:text-gray-300">{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
            
            <div>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
                What Our Fees Don't Cover
              </h2>
              
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                {Amenities_demos2.map((item, index) => (
                  <div key={index} className="flex items-start space-x-3 p-4 rounded-lg bg-white/80 dark:bg-neutral-800/80 backdrop-blur-sm border border-red-100 dark:border-red-800/30 hover:border-red-200 dark:hover:border-red-700/40 transition-all duration-200">
                    <div className="flex-shrink-0 mt-0.5">{item.icon}</div>
                    <span className="text-gray-700 dark:text-gray-300">{item.name}</span>
                  </div>
                ))}
              </div>
              
              <p className="mt-6 text-gray-600 dark:text-gray-300">
                These additional costs have been captured in the pricing table above so you can have
                a clear idea of the overall cost involved in the process.
              </p>
              
              <div className="mt-8">
                <VisaButton 
                  onClick={applyBtn}
                  className="text-base px-8 py-3.5 font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-0.5"
                >
                  Apply Now
                </VisaButton>
              </div>
            </div>
          </div>
        </section>

        {/* Visa Requirements Section */}
        <section className="bg-gradient-to-tr from-white via-white to-green-50 dark:from-neutral-900 dark:via-neutral-900 dark:to-green-950/20 rounded-2xl shadow-sm overflow-hidden border border-green-100/50 dark:border-green-900/30">
          <div className="p-6 md:p-8 lg:p-10">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white mb-2">
              Visa Requirements
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-8">
              It is important to note that all visa applicants will be asked to
              provide certain personal documents as required by the particular
              embassy. These documents vary depending on which visa is being
              applied for.
            </p>

            <div className="flex flex-col lg:flex-row gap-10">
              <div className="lg:w-3/5">
                <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200 mb-4">
                  Basic Requirements for All Applications
                </h3>
                
                <div className="space-y-4">
                  {Amenities_demos3.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-start space-x-3 p-4 rounded-lg bg-white/70 dark:bg-neutral-800/70 backdrop-blur-sm border border-gray-100 dark:border-neutral-700"
                    >
                      <div className="flex-shrink-0 mt-0.5">{item.icon}</div>
                      <span className="text-gray-700 dark:text-gray-300">{item.name}</span>
                    </div>
                  ))}
                </div>
                
                {/* Mobile animation - shown only on small screens */}
                <div className="mt-8 lg:hidden">
                  <div className="rounded-xl overflow-hidden bg-white dark:bg-neutral-800 flex items-center justify-center relative" style={{ height: '200px' }}>
                    {animationLoading && (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-50 dark:bg-neutral-800 z-10">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-500"></div>
                      </div>
                    )}
                    <Player
                      autoplay
                      loop
                      src={visaAnimation}
                      className="w-full"
                      style={{ height: '200px' }}
                      speed={0.8}
                      background="transparent"
                      rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                      onEvent={event => {
                        if (event === 'load') setAnimationLoading(false);
                      }}
                    />
                  </div>
                  <div className="mt-4 text-center">
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">Ready to start your journey?</p>
                    <VisaButton 
                      onClick={applyBtn}
                      className="text-sm px-5 py-2.5 rounded-lg shadow-sm hover:shadow-md"
                    >
                      Apply Now
                    </VisaButton>
                  </div>
                </div>
              </div>
              
              {/* Desktop animation - hidden on small screens */}
              <div className="hidden lg:block lg:w-2/5">
                <div className="flex flex-col space-y-4">
                  <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">
                    Hassle-free Visa Application
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">
                    Our streamlined process makes obtaining your travel documents quick and stress-free with expert guidance at every step.
                  </p>
                  <div className="rounded-xl overflow-hidden bg-white dark:bg-neutral-800 flex items-center justify-center relative" style={{ minHeight: '280px', maxHeight: '320px' }}>
                    {animationLoading && (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-50 dark:bg-neutral-800 z-10">
                        <div className="flex flex-col items-center space-y-2">
                          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-green-500"></div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">Loading animation...</p>
                        </div>
                      </div>
                    )}
                    <Player
                      autoplay
                      loop
                      src={visaAnimation}
                      className="w-full"
                      style={{ minHeight: '280px', maxHeight: '320px' }}
                      speed={0.8}
                      background="transparent"
                      rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                      onEvent={event => {
                        if (event === 'load') setAnimationLoading(false);
                      }}
  // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
                      renderError={() => (
                        <div className="flex flex-col items-center justify-center p-4 h-full">
                          <svg className="w-10 h-10 text-gray-400 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                          <p className="text-gray-500 text-center">Animation could not be loaded</p>
                        </div>
                      )}
                    />
                  </div>
                  <div className="mt-6 text-center">
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">Ready to start your journey?</p>
                    <VisaButton 
                      onClick={applyBtn}
                      className="text-sm px-5 py-2.5 rounded-lg shadow-sm hover:shadow-md"
                    >
                      Apply Now
                    </VisaButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Additional Requirements Section */}
        <section className="bg-gradient-to-bl from-white via-gray-50 to-gray-100 dark:from-neutral-900 dark:via-neutral-900 dark:to-neutral-800 rounded-2xl shadow-sm p-6 md:p-8 lg:p-10 border border-gray-100 dark:border-neutral-800">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white mb-6">
            Additional Requirements by Occupation
          </h2>
          
          <div className="rounded-xl overflow-hidden border border-gray-200 dark:border-neutral-800">
            <Accordion items={additionRequirements} />
          </div>
          
          <div className="mt-8 space-y-4">
            <p className="text-gray-600 dark:text-gray-300">
              If you do not fall under any of the above categories, it means you
              cannot provide the above documents and therefore we are unable to
              assist you with the visa application.
            </p>
            
            <p className="text-gray-600 dark:text-gray-300">
              Other documents will be needed based on the embassy and the
              circumstance of the applicant. Our trained consultant will let you
              know any other documents required and will advise you accordingly,
              once you make payment for our services.
            </p>
            
            <div className="p-4 bg-amber-50 dark:bg-amber-900/20 border-l-4 border-amber-500 rounded-r-lg">
              <p className="text-amber-800 dark:text-amber-200">
                Adansi Travels cannot obtain personal documents like those stated
                above for the applicant and it's your responsibility to ensure
                you can get those before making payment.
              </p>
            </div>
          </div>
        </section>

        {/* FAQs Section */}
        <section className="bg-gradient-to-tr from-amber-50 via-white to-white dark:from-amber-950/20 dark:via-neutral-900 dark:to-neutral-900 rounded-2xl shadow-sm p-6 md:p-8 lg:p-10 border border-amber-100/50 dark:border-amber-900/30">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white mb-6">
            Frequently Asked Questions
          </h2>
          
          <div className="rounded-xl overflow-hidden border border-gray-200 dark:border-neutral-800 mb-8">
            <Accordion items={faq} />
          </div>
          
          <div className="p-4 bg-gray-50 dark:bg-neutral-800 rounded-lg">
            <p className="text-gray-600 dark:text-gray-300 text-sm">
              The success rate shown is for educative purpose and not a guarantee
              as your personal circumstance will determine the visa success chance
              for you. The success rate is calculated based on our overall visa
              application for our collective clients and not individual visa
              success rate.
            </p>
          </div>
          
          <div className="flex w-full items-center justify-center mt-10">
            <VisaButton 
              onClick={applyBtn}
              className="text-base px-10 py-4 font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-0.5"
            >
              Start Your Visa Application Now
            </VisaButton>
          </div>
        </section>
      </div>
      
      {renderApplyModalBox()}
    </div>
  );
};

export default Page;