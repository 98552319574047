import { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

/**
 * Enhanced hook for traditional email/password authentication
 * @param {string} email - Email for authentication
 * @param {string} password - Password for authentication
 * @returns {object} Authentication state and data
 */
export const useAuthenticateCustomers = (email, password) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attemptLogin, setAttemptLogin] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      // Only proceed if both email and password are provided
      if (!email || !password) {
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        // Get Firebase auth instance
        const auth = getAuth();
        
        // Attempt sign in with Firebase
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Fetch user data from backend
        const response = await axios.post(
          `${BASE_URL}website_fetch_customer_with_uid`,
          { authid: user.uid },
          { headers: { 'adway-key': API_KEY } }
        );

        setData(response.data);
      } catch (error) {
        console.error('Authentication failed:', error);
        
        // Clean error message
        let errorMessage = 'Authentication failed';
        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = 'Invalid email address';
            break;
          case 'auth/user-disabled':
            errorMessage = 'This account has been disabled';
            break;
          case 'auth/user-not-found':
            errorMessage = 'No account found with this email';
            break;
          case 'auth/wrong-password':
            errorMessage = 'Incorrect password';
            break;
          case 'auth/too-many-requests':
            errorMessage = 'Too many unsuccessful login attempts. Please try again later';
            break;
          default:
            errorMessage = error.message || 'Authentication failed';
        }
        
        setError(error);
        setData({ status: false, message: errorMessage });
      } finally {
        setIsLoading(false);
        setAttemptLogin(false);
      }
    };

    // Only run the effect if we have credentials and an attempt is made
    if ((email && password) || attemptLogin) {
      fetchData();
    }
  }, [email, password, attemptLogin]);

  /**
   * Manually trigger authentication
   */
  const authenticate = () => {
    if (email && password) {
      setAttemptLogin(true);
    }
  };

  return { 
    data, 
    isLoading, 
    error,
    authenticate
  };
};

export default useAuthenticateCustomers;