

// import { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { 
//   getAuth, 
//   RecaptchaVerifier, 
//   signInWithPhoneNumber, 
//   PhoneAuthProvider,
//   signInWithCredential
// } from 'firebase/auth';

// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const API_KEY = process.env.REACT_APP_API_KEY;

// /**
//  * Enhanced hook for phone authentication with better reCAPTCHA handling
//  */
// export const usePhoneAuthentication = () => {
//   // State management
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [userData, setUserData] = useState(null);
//   const [verificationId, setVerificationId] = useState(null);
//   const [recaptchaResolved, setRecaptchaResolved] = useState(false);
  
//   // Use ref to persist verifier between renders and manage lifecycle better
//   const recaptchaVerifierRef = useRef(null);
//   const containerIdRef = useRef(null);
  
//   /**
//    * Safe method to clear existing reCAPTCHA with proper error handling
//    */
//   const safelyClearRecaptcha = () => {
//     if (recaptchaVerifierRef.current) {
//       try {
//         // Check if it has a clear method before calling
//         if (typeof recaptchaVerifierRef.current.clear === 'function') {
//           recaptchaVerifierRef.current.clear();
//         }
//       } catch (e) {
//         console.warn('Non-critical: Error clearing existing reCAPTCHA:', e);
//         // Continue execution - this is a non-critical error
//       } finally {
//         // Always reset the reference
//         recaptchaVerifierRef.current = null;
//       }
//     }
    
//     // Attempt to clear the DOM container if it exists
//     try {
//       if (containerIdRef.current) {
//         const container = document.getElementById(containerIdRef.current);
//         if (container) {
//           container.innerHTML = '';
//         }
//       }
//     } catch (e) {
//       console.warn('Non-critical: Error clearing reCAPTCHA container:', e);
//     }
//   };
  
//   /**
//    * Initialize reCAPTCHA verifier with improved error handling
//    * @param {string} containerId - DOM element ID for reCAPTCHA container
//    * @returns {Promise<boolean>} - Success status
//    */
//   const initializeRecaptcha = (containerId = 'recaptcha-container') => {
//     setIsLoading(true);
//     setError(null);
    
//     // Store container ID for cleanup
//     containerIdRef.current = containerId;
    
//     // First clear any existing verifier
//     safelyClearRecaptcha();
    
//     try {
//       // Check if container exists
//       const container = document.getElementById(containerId);
//       if (!container) {
//         throw new Error(`reCAPTCHA container with ID "${containerId}" not found`);
//       }
      
//       // Reset container state
//       container.innerHTML = ''; 
//       container.style.display = 'block';
      
//       // Create new verifier
//       const auth = getAuth();
      
//       const verifierOptions = {
//         size: 'normal',
//         callback: () => {
//           console.log('reCAPTCHA resolved successfully');
//           setRecaptchaResolved(true);
//           setError(null);
//         },
//         'expired-callback': () => {
//           console.log('reCAPTCHA expired');
//           setError('reCAPTCHA verification expired. Please refresh and try again.');
//           setRecaptchaResolved(false);
//         },
//         'error-callback': (error) => {
//           console.error('reCAPTCHA error:', error);
//           setError('Error with reCAPTCHA verification. Please try again.');
//           setRecaptchaResolved(false);
//         }
//       };
      
//       try {
//         const verifier = new RecaptchaVerifier(auth, containerId, verifierOptions);
        
//         // Store verifier in ref
//         recaptchaVerifierRef.current = verifier;
        
//         // Explicitly render and handle promises
//         return verifier.render()
//           .then(() => {
//             console.log('reCAPTCHA rendered successfully');
//             setIsLoading(false);
//             return true;
//           })
//           .catch((renderError) => {
//             console.error('Error rendering reCAPTCHA:', renderError);
//             setError('Failed to render reCAPTCHA. Please refresh and try again.');
//             setIsLoading(false);
//             return false;
//           });
//       } catch (verifierError) {
//         console.error('Error creating reCAPTCHA verifier:', verifierError);
//         setError('Failed to initialize reCAPTCHA verification. Please refresh the page.');
//         setIsLoading(false);
//         return false;
//       }
//     } catch (error) {
//       console.error('Error initializing reCAPTCHA:', error);
//       setError(error.message || 'Failed to initialize reCAPTCHA');
//       setIsLoading(false);
//       return false;
//     }
//   };
  
//   /**
//    * Send OTP to the phone number with improved error handling
//    * @param {string} phoneNum - Phone number with country code
//    * @returns {Promise<boolean>} - Success status
//    */
//   const sendOtpToPhone = async (phoneNum) => {
//     if (!phoneNum) {
//       setError('Phone number is required');
//       return false;
//     }
    
//     if (!recaptchaVerifierRef.current) {
//       setError('Please complete the reCAPTCHA verification first');
//       return false;
//     }
    
//     if (!recaptchaResolved) {
//       setError('Please solve the reCAPTCHA challenge first');
//       return false;
//     }
    
//     setIsLoading(true);
//     setPhoneNumber(phoneNum);
//     setError(null);
    
//     try {
//       // Format phone number
//       const formattedPhoneNumber = phoneNum.startsWith('+') ? phoneNum : `+${phoneNum}`;
      
//       // Get auth instance
//       const auth = getAuth();
      
//       // Send verification code with proper error handling
//       try {
//         const confirmationResult = await signInWithPhoneNumber(
//           auth, 
//           formattedPhoneNumber, 
//           recaptchaVerifierRef.current
//         );
        
//         // Store verification ID
//         setVerificationId(confirmationResult.verificationId);
        
//         setIsLoading(false);
//         return true;
//       } catch (smsError) {
//         console.error('Error sending SMS verification:', smsError);
        
//         // Handle specific Firebase errors
//         let errorMessage = 'Failed to send verification code';
//         if (smsError.code) {
//           switch (smsError.code) {
//             case 'auth/invalid-phone-number':
//               errorMessage = 'Invalid phone number format. Please include country code (e.g. +1234567890)';
//               break;
//             case 'auth/captcha-check-failed':
//               errorMessage = 'reCAPTCHA verification failed. Please try again';
//               break;
//             case 'auth/quota-exceeded':
//               errorMessage = 'SMS quota exceeded. Please try again later';
//               break;
//             case 'auth/missing-phone-number':
//               errorMessage = 'Phone number is required';
//               break;
//             case 'auth/invalid-recipient':
//               errorMessage = 'Invalid phone number';
//               break;
//             case 'auth/too-many-requests':
//               errorMessage = 'Too many requests. Please try again later';
//               break;
//             default:
//               errorMessage = smsError.message || 'Failed to send verification code';
//           }
//         }
        
//         setError(errorMessage);
//         setIsLoading(false);
//         return false;
//       }
//     } catch (error) {
//       console.error('Error in sendOtpToPhone:', error);
//       setError(error.message || 'Failed to send verification code');
//       setIsLoading(false);
//       return false;
//     }
//   };
  
//   /**
//    * Verify OTP code with improved error handling
//    * @param {string} otp - 6-digit OTP code
//    * @returns {Promise<object|boolean>} - User data or false on failure
//    */
//   const verifyOtp = async (otp) => {
//     if (!verificationId) {
//       setError('Verification session expired. Please request a new code');
//       return false;
//     }
    
//     if (!otp || !/^\d{6}$/.test(otp)) {
//       setError('Please enter a valid 6-digit verification code');
//       return false;
//     }
    
//     setIsLoading(true);
//     setError(null);
    
//     try {
//       // Get auth instance
//       const auth = getAuth();
      
//       // Create credential
//       const credential = PhoneAuthProvider.credential(verificationId, otp);
      
//       // Sign in with credential
//       try {
//         const userCredential = await signInWithCredential(auth, credential);
//         const user = userCredential.user;
        
//         // Fetch user data from backend
//         try {
//           const response = await axios.post(
//             `${BASE_URL}website_fetch_customer_with_uid`,
//             { authid: user.uid },
//             { headers: { 'adway-key': API_KEY } }
//           );
          
//           setUserData(response.data);
//           setIsLoading(false);
//           return response.data;
//         } catch (apiError) {
//           console.error('Error fetching user data from API:', apiError);
//           setError('Successfully verified but failed to fetch account details');
//           setIsLoading(false);
//           return false;
//         }
//       } catch (credentialError) {
//         console.error('Error verifying OTP:', credentialError);
        
//         // Handle specific verification errors
//         let errorMessage = 'Verification failed';
//         if (credentialError.code) {
//           switch (credentialError.code) {
//             case 'auth/invalid-verification-code':
//               errorMessage = 'Invalid verification code. Please check and try again';
//               break;
//             case 'auth/code-expired':
//               errorMessage = 'Verification code has expired. Please request a new one';
//               break;
//             case 'auth/invalid-credential':
//               errorMessage = 'Invalid verification credentials';
//               break;
//             case 'auth/user-disabled':
//               errorMessage = 'This account has been disabled';
//               break;
//             default:
//               errorMessage = credentialError.message || 'Verification failed';
//           }
//         }
        
//         setError(errorMessage);
//         setIsLoading(false);
//         return false;
//       }
//     } catch (error) {
//       console.error('Error in verifyOtp:', error);
//       setError(error.message || 'Verification failed');
//       setIsLoading(false);
//       return false;
//     }
//   };
  
//   /**
//    * Clean up resources with improved error handling
//    */
//   const cleanup = () => {
//     // First safely clear recaptcha
//     safelyClearRecaptcha();
    
//     // Reset all state
//     setVerificationId(null);
//     setRecaptchaResolved(false);
//     setError(null);
    
//     // Clear container if it exists
//     try {
//       if (containerIdRef.current) {
//         const container = document.getElementById(containerIdRef.current);
//         if (container) {
//           container.style.display = 'none';
//         }
//       }
//     } catch (e) {
//       console.warn('Error hiding reCAPTCHA container:', e);
//     }
//   };
  
//   /**
//    * Reset the authentication state
//    */
//   const resetState = () => {
//     setPhoneNumber('');
//     setVerificationId(null);
//     setRecaptchaResolved(false);
//     setError(null);
//     setUserData(null);
//   };
  
//   // Clean up on unmount
//   useEffect(() => {
//     return () => {
//       cleanup();
//     };
//   }, []);
  
//   return {
//     phoneNumber,
//     isLoading,
//     error,
//     userData,
//     recaptchaResolved,
//     initializeRecaptcha,
//     sendOtpToPhone,
//     verifyOtp,
//     cleanup,
//     resetState
//   };
// };

// export default usePhoneAuthentication;



import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { 
  getAuth, 
  RecaptchaVerifier, 
  signInWithPhoneNumber, 
  PhoneAuthProvider,
  signInWithCredential
} from 'firebase/auth';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

/**
 * Enhanced hook for phone authentication with better reCAPTCHA handling
 */
export const usePhoneAuthentication = () => {
  // State management
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [recaptchaResolved, setRecaptchaResolved] = useState(false);
  
  // Use ref to persist verifier between renders and manage lifecycle better
  const recaptchaVerifierRef = useRef(null);
  const containerIdRef = useRef(null);
  
  /**
   * Safe method to clear existing reCAPTCHA with proper error handling
   */
  const safelyClearRecaptcha = () => {
    if (recaptchaVerifierRef.current) {
      try {
        // Check if it has a clear method before calling
        if (typeof recaptchaVerifierRef.current.clear === 'function') {
          recaptchaVerifierRef.current.clear();
        }
      } catch (e) {
        console.warn('Non-critical: Error clearing existing reCAPTCHA:', e);
        // Continue execution - this is a non-critical error
      } finally {
        // Always reset the reference
        recaptchaVerifierRef.current = null;
      }
    }
    
    // Attempt to clear the DOM container if it exists
    try {
      if (containerIdRef.current) {
        const container = document.getElementById(containerIdRef.current);
        if (container) {
          container.innerHTML = '';
        }
      }
    } catch (e) {
      console.warn('Non-critical: Error clearing reCAPTCHA container:', e);
    }
  };
  
  /**
   * Initialize reCAPTCHA verifier with improved error handling
   * @param {string} containerId - DOM element ID for reCAPTCHA container
   * @returns {Promise<boolean>} - Success status
   */
  const initializeRecaptcha = (containerId = 'recaptcha-container') => {
    setIsLoading(true);
    setError(null);
    
    // Store container ID for cleanup
    containerIdRef.current = containerId;
    
    // First clear any existing verifier
    safelyClearRecaptcha();
    
    try {
      // Check if container exists
      const container = document.getElementById(containerId);
      if (!container) {
        throw new Error(`reCAPTCHA container with ID "${containerId}" not found`);
      }
      
      // Reset container state and set explicit dimensions to ensure visibility
      container.innerHTML = ''; 
      container.style.display = 'block';
      container.style.minHeight = '78px';
      container.style.width = '100%';
      container.style.marginTop = '10px';
      container.style.marginBottom = '10px';
      
      // Create new verifier
      const auth = getAuth();
      
      const verifierOptions = {
        size: 'normal', // Use normal size (not invisible) to ensure it's visible
        sitekey: undefined, // Let Firebase use the project's default sitekey
        callback: () => {
          console.log('reCAPTCHA resolved successfully');
          setRecaptchaResolved(true);
          setError(null);
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired');
          setError('reCAPTCHA verification expired. Please refresh and try again.');
          setRecaptchaResolved(false);
        },
        'error-callback': (error) => {
          console.error('reCAPTCHA error:', error);
          setError('Error with reCAPTCHA verification. Please try again.');
          setRecaptchaResolved(false);
        }
      };
      
      try {
        // Create the verifier with explicit force visible mode
        console.log('Creating new reCAPTCHA verifier...');
        const verifier = new RecaptchaVerifier(auth, containerId, verifierOptions);
        
        // Store verifier in ref
        recaptchaVerifierRef.current = verifier;
        
        // Explicitly render and handle promises
        console.log('Rendering reCAPTCHA...');
        return verifier.render()
          .then((widgetId) => {
            console.log('reCAPTCHA rendered successfully with widget ID:', widgetId);
            setIsLoading(false);
            
            // Force a visual check to ensure it's displaying
            setTimeout(() => {
              const container = document.getElementById(containerId);
              if (container && container.childElementCount === 0) {
                console.warn('reCAPTCHA may not be visible - container has no children');
                setError('reCAPTCHA not displaying properly. Please refresh the page.');
              }
            }, 1000);
            
            return true;
          })
          .catch((renderError) => {
            console.error('Error rendering reCAPTCHA:', renderError);
            setError('Failed to render reCAPTCHA. Please refresh and try again.');
            setIsLoading(false);
            return false;
          });
      } catch (verifierError) {
        console.error('Error creating reCAPTCHA verifier:', verifierError);
        setError('Failed to initialize reCAPTCHA verification. Please refresh the page.');
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      console.error('Error initializing reCAPTCHA:', error);
      setError(error.message || 'Failed to initialize reCAPTCHA');
      setIsLoading(false);
      return false;
    }
  };
  
  /**
   * Send OTP to the phone number with improved error handling
   * @param {string} phoneNum - Phone number with country code
   * @returns {Promise<boolean>} - Success status
   */
  const sendOtpToPhone = async (phoneNum) => {
    if (!phoneNum) {
      setError('Phone number is required');
      return false;
    }
    
    if (!recaptchaVerifierRef.current) {
      setError('Please complete the reCAPTCHA verification first');
      return false;
    }
    
    if (!recaptchaResolved) {
      setError('Please solve the reCAPTCHA challenge first');
      return false;
    }
    
    setIsLoading(true);
    setPhoneNumber(phoneNum);
    setError(null);
    
    try {
      // Format phone number
      const formattedPhoneNumber = phoneNum.startsWith('+') ? phoneNum : `+${phoneNum}`;
      
      // Get auth instance
      const auth = getAuth();
      
      // Send verification code with proper error handling
      try {
        console.log('Sending verification code to:', formattedPhoneNumber);
        const confirmationResult = await signInWithPhoneNumber(
          auth, 
          formattedPhoneNumber, 
          recaptchaVerifierRef.current
        );
        
        // Store verification ID
        setVerificationId(confirmationResult.verificationId);
        console.log('Verification code sent successfully');
        
        setIsLoading(false);
        return true;
      } catch (smsError) {
        console.error('Error sending SMS verification:', smsError);
        
        // Handle specific Firebase errors
        let errorMessage = 'Failed to send verification code';
        if (smsError.code) {
          switch (smsError.code) {
            case 'auth/invalid-phone-number':
              errorMessage = 'Invalid phone number format. Please include country code (e.g. +1234567890)';
              break;
            case 'auth/captcha-check-failed':
              errorMessage = 'reCAPTCHA verification failed. Please try again';
              break;
            case 'auth/quota-exceeded':
              errorMessage = 'SMS quota exceeded. Please try again later';
              break;
            case 'auth/missing-phone-number':
              errorMessage = 'Phone number is required';
              break;
            case 'auth/invalid-recipient':
              errorMessage = 'Invalid phone number';
              break;
            case 'auth/too-many-requests':
              errorMessage = 'Too many requests. Please try again later';
              break;
            default:
              errorMessage = smsError.message || 'Failed to send verification code';
          }
        }
        
        setError(errorMessage);
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      console.error('Error in sendOtpToPhone:', error);
      setError(error.message || 'Failed to send verification code');
      setIsLoading(false);
      return false;
    }
  };
  
  /**
   * Force recaptcha to reload - useful as a user-triggered action
   */
  const forceRecaptchaReload = () => {
    const containerId = containerIdRef.current || 'recaptcha-container';
    safelyClearRecaptcha();
    
    // Add a small delay before reinitializing
    setTimeout(() => {
      initializeRecaptcha(containerId);
    }, 100);
  };
  
  /**
   * Verify OTP code with improved error handling
   * @param {string} otp - 6-digit OTP code
   * @returns {Promise<object|boolean>} - User data or false on failure
   */
  const verifyOtp = async (otp) => {
    if (!verificationId) {
      setError('Verification session expired. Please request a new code');
      return false;
    }
    
    if (!otp || !/^\d{6}$/.test(otp)) {
      setError('Please enter a valid 6-digit verification code');
      return false;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      // Get auth instance
      const auth = getAuth();
      
      // Create credential
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      
      // Sign in with credential
      try {
        const userCredential = await signInWithCredential(auth, credential);
        const user = userCredential.user;
        
        // Fetch user data from backend
        try {
          const response = await axios.post(
            `${BASE_URL}website_fetch_customer_with_uid`,
            { authid: user.uid },
            { headers: { 'adway-key': API_KEY } }
          );
          
          setUserData(response.data);
          setIsLoading(false);
          return response.data;
        } catch (apiError) {
          console.error('Error fetching user data from API:', apiError);
          setError('Successfully verified but failed to fetch account details');
          setIsLoading(false);
          return false;
        }
      } catch (credentialError) {
        console.error('Error verifying OTP:', credentialError);
        
        // Handle specific verification errors
        let errorMessage = 'Verification failed';
        if (credentialError.code) {
          switch (credentialError.code) {
            case 'auth/invalid-verification-code':
              errorMessage = 'Invalid verification code. Please check and try again';
              break;
            case 'auth/code-expired':
              errorMessage = 'Verification code has expired. Please request a new one';
              break;
            case 'auth/invalid-credential':
              errorMessage = 'Invalid verification credentials';
              break;
            case 'auth/user-disabled':
              errorMessage = 'This account has been disabled';
              break;
            default:
              errorMessage = credentialError.message || 'Verification failed';
          }
        }
        
        setError(errorMessage);
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      console.error('Error in verifyOtp:', error);
      setError(error.message || 'Verification failed');
      setIsLoading(false);
      return false;
    }
  };
  
  /**
   * Clean up resources with improved error handling
   */
  const cleanup = () => {
    // First safely clear recaptcha
    safelyClearRecaptcha();
    
    // Reset all state
    setVerificationId(null);
    setRecaptchaResolved(false);
    setError(null);
    
    // Clear container if it exists
    try {
      if (containerIdRef.current) {
        const container = document.getElementById(containerIdRef.current);
        if (container) {
          container.style.display = 'none';
        }
      }
    } catch (e) {
      console.warn('Error hiding reCAPTCHA container:', e);
    }
  };
  
  /**
   * Reset the authentication state
   */
  const resetState = () => {
    setPhoneNumber('');
    setVerificationId(null);
    setRecaptchaResolved(false);
    setError(null);
    setUserData(null);
  };
  
  // Clean up on unmount
  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);
  
  return {
    phoneNumber,
    isLoading,
    error,
    userData,
    recaptchaResolved,
    initializeRecaptcha,
    forceRecaptchaReload,
    sendOtpToPhone,
    verifyOtp,
    cleanup,
    resetState
  };
};

export default usePhoneAuthentication;