import { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  getAuth, 
  sendSignInLinkToEmail, 
  isSignInWithEmailLink, 
  signInWithEmailLink 
} from 'firebase/auth';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

/**
 * Enhanced hook for email authentication
 */
export const useEmailAuthentication = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [userData, setUserData] = useState(null);
  
  /**
   * Send OTP to email (actually sends a sign-in link)
   * @param {string} emailAddress - Email to send sign-in link to
   * @returns {Promise<boolean>} - Success status
   */
  const sendOtpToEmail = async (emailAddress) => {
    if (!emailAddress) {
      setError('Email address is required');
      return false;
    }
    
    setIsLoading(true);
    setEmail(emailAddress);
    setError(null);
    
    try {
      // Check if user exists (optional, could be removed if not needed)
      // This is a workaround since we can't directly check if email exists
      const auth = getAuth();
      
      try {
        // Try to check email existence using a backend endpoint
        // This is safer than using signInWithEmailAndPassword
        // In a real app, you would implement this endpoint
        const emailCheckResponse = await axios.post(
          `${BASE_URL}check_email_exists`,
          { email: emailAddress },
          { headers: { 'adway-key': API_KEY } }
        );
        
        if (!emailCheckResponse.data.exists) {
          throw new Error("No account found with this email");
        }
      } catch (checkError) {
        // If the backend doesn't have this endpoint, we can skip this check
        // or implement an alternative check method
        console.warn("Email check failed, proceeding with sending OTP:", checkError);
      }
      
      // Configure action code settings for the email link
      const actionCodeSettings = {
        url: `${window.location.origin}/login?email=${emailAddress}`,
        handleCodeInApp: true
      };
      
      // Send email verification link (Firebase handles this as a passwordless sign-in)
      await sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings);
      
      // Save email in localStorage for verification later
      window.localStorage.setItem('emailForSignIn', emailAddress);
      
      setOtpSent(true);
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error('Error sending email OTP:', error);
      
      // Clean error message
      let errorMessage = 'Failed to send verification email';
      if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email address';
      } else if (error.code === 'auth/user-disabled') {
        errorMessage = 'This account has been disabled';
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      setError(errorMessage);
      setIsLoading(false);
      return false;
    }
  };
  
  /**
   * Verify email OTP (using sign-in link)
   * @returns {Promise<object|boolean>} - User data or false on failure
   */
  const verifyEmailOtp = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const auth = getAuth();
      
      // Check if current URL is a sign-in link
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Get email from localStorage
        let emailForSignIn = window.localStorage.getItem('emailForSignIn');
        
        if (!emailForSignIn) {
          // If email not found in storage, prompt user
          emailForSignIn = window.prompt('Please provide your email for confirmation');
          
          if (!emailForSignIn) {
            throw new Error("Email is required to complete sign-in");
          }
        }
        
        // Complete sign-in with email link
        const userCredential = await signInWithEmailLink(auth, emailForSignIn, window.location.href);
        const user = userCredential.user;
        
        // Clear email from localStorage
        window.localStorage.removeItem('emailForSignIn');
        
        // Fetch user data from backend
        const response = await axios.post(
          `${BASE_URL}website_fetch_customer_with_uid`,
          { authid: user.uid },
          { headers: { 'adway-key': API_KEY } }
        );
        
        setUserData(response.data);
        setIsLoading(false);
        return response.data;
      } else {
        // If not on a sign-in link, we're likely in a fallback verification flow
        // For custom OTP input, implement your backend verification here
        
        // For demo purposes only - in a real app, verify with your backend
        throw new Error("Email verification link is invalid");
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      
      // Clean error message
      let errorMessage = 'Email verification failed';
      if (error.code === 'auth/expired-action-code') {
        errorMessage = 'Verification link has expired';
      } else if (error.code === 'auth/invalid-action-code') {
        errorMessage = 'Invalid verification link';
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      setError(errorMessage);
      setIsLoading(false);
      return false;
    }
  };
  
  /**
   * Verify manual OTP code (requires backend implementation)
   * @param {string} otp - OTP code entered by user
   * @returns {Promise<object|boolean>} - User data or false on failure
   */
  const verifyManualOtp = async (otp) => {
    if (!otp || !email) {
      setError('OTP and email are required');
      return false;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      // This would need to be implemented by your backend
      // Firebase doesn't provide a built-in way to verify numeric OTPs for email
      
      // Example implementation with your backend:
      const response = await axios.post(
        `${BASE_URL}verify_email_otp`,
        { email, otp },
        { headers: { 'adway-key': API_KEY } }
      );
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Invalid OTP');
      }
      
      // If verification is successful, get user data
      if (response.data.authid) {
        const userDataResponse = await axios.post(
          `${BASE_URL}website_fetch_customer_with_uid`,
          { authid: response.data.authid },
          { headers: { 'adway-key': API_KEY } }
        );
        
        setUserData(userDataResponse.data);
        setIsLoading(false);
        return userDataResponse.data;
      } else {
        throw new Error('User authentication failed');
      }
    } catch (error) {
      console.error('Error verifying manual OTP:', error);
      setError(error.message || 'OTP verification failed');
      setIsLoading(false);
      return false;
    }
  };
  
  // Check if current page is loaded with an email sign-in link
  useEffect(() => {
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      verifyEmailOtp();
    }
  }, []);
  
  return {
    email,
    isLoading,
    error,
    otpSent,
    userData,
    sendOtpToEmail,
    verifyEmailOtp,
    verifyManualOtp
  };
};

export default useEmailAuthentication;